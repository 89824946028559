import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faUser, faHandshake, faUserTie } from '@fortawesome/free-solid-svg-icons';
import './StartupPage.css';

const StartupPage = () => {
  return (
    <div className="startup-page">
      <Container fluid className="d-flex flex-column justify-content-between align-items-center h-100">
        <Row className="w-100 text-center mb-4">
          <Col>
            <h1 className="page-title">Book My Hall</h1>
          </Col>
        </Row>
        <Row className="w-100 text-end mb-4">
          <Col>
            <div className="social-icons">
              <a href="https://facebook.com" className="social-icon"><i className="fa fa-facebook"></i></a>
              <a href="https://twitter.com" className="social-icon"><i className="fa fa-twitter"></i></a>
              <a href="https://instagram.com" className="social-icon"><i className="fa fa-instagram"></i></a>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center w-100 flex-grow-1">
          <Col xs={12} md={4} className="mb-3">
            <Card className="text-center transparent-card">
              <Card.Body>
                <FontAwesomeIcon icon={faUserTie} size="4x" />
                <Card.Title>Admin</Card.Title>
                <Card.Link href="/admin/login"><strong>Welcome Admin</strong></Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <Card className="text-center transparent-card">
              <Card.Body>
                <FontAwesomeIcon icon={faUser} size="4x" />
                <Card.Title>User</Card.Title>
                <Card.Link href="/user/login"><strong>Welcome User</strong></Card.Link>
              </Card.Body>
            </Card>
          </Col>
         
        </Row>
      </Container>
    </div>
  );
};

export default StartupPage;
