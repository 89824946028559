import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './AdminHomeNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './showbooking.css';

const ShowBooking = () => {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:5000/api/bookings')
            .then(response => {
                const currentDate = new Date();
                /* const previousDayDate = new Date(currentDate); */
                currentDate.setDate(currentDate.getDate() + 1); // set to the next day to compare against booking dates
              /*   previousDayDate.setDate(previousDayDate.getDate() - 1); */
                const filtered = response.data.filter(booking => {
                    const bookingDate = new Date(booking.booking_date);
                    return (
                       /*  bookingDate.getFullYear() === previousDayDate.getFullYear() &&
                        bookingDate.getMonth() === previousDayDate.getMonth() &&
                        bookingDate.getDate() === previousDayDate.getDate() */
                        bookingDate.getFullYear() === currentDate.getFullYear() &&
                        bookingDate.getMonth() === currentDate.getMonth() &&
                        bookingDate.getDate() === currentDate.getDate()
                    );
                });
                setFilteredBookings(filtered);
                setBookings(response.data);
            })
            .catch(error => console.error('Error fetching bookings:', error));
    }, []);

    return (
        <div className="show-booking">
            <Navbar />
            <div className="container mt-5">
                <h2 className="text-center mb-4">Booked Halls</h2>
                <div className="row">
                    {filteredBookings.map(booking => (
                        <div key={booking.booking_id} className="col-md-6 mb-4">
                            <div className="card border-success">
                                <div className="card-body">
                                    <h5 className="card-title">{booking.hall_name}</h5>
                                    <p className="card-text">Booking Date: {new Date(booking.booking_date).toLocaleDateString('en-GB')}</p>
                                    <p className="card-text">User Name: {booking.user_name}</p>
                                    <p className="card-text">User Mobile: {booking.user_mobile}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ShowBooking;
