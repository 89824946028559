// src/admin/AdminHome.js
import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';



const UserHome = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/user/login');
  };

  return (
    <div className="user-home">
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand>User Home</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link onClick={() => navigate('/user/showhall')}>Book Hall</Nav.Link>
  
          <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
        </Nav>
      </Navbar>
      <Container>
        <h2>Welcome User</h2>
      </Container>
    </div>
  );
};

export default UserHome;
